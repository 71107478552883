import { Component, Inject, isDevMode } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from './utils/token.service';
import { CommonService } from './services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'control-contractor-portal-ui';

  testData: any;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public httpClient: HttpClient,
    private route:ActivatedRoute,
    private router:Router,
    private tokenService:TokenService,
    private commonService:CommonService
  ) {
    console.log(this.route)
    console.log(this.route.url)
    this.route.queryParams.subscribe(qParams=>{
      console.log(qParams)
      if(qParams?.contractor_id) {
        this.tokenService.generateToken(qParams?.contractor_id).then((token) => {
          console.log('Generated Token:', token);
          const req={
            token,
            contractor_id:qParams?.contractor_id,
            project_id:qParams?.project_id
          }
          this.tokenService.verifyToken(req).subscribe((result:{success:boolean,message:string})=>{
            if(result?.success){
              this.commonService.isFranchisee=true;
              this.commonService.franchiseeContractor_id=qParams?.contractor_id;
              this.commonService.franchiseeProjectid=qParams?.project_id;
              this.commonService.business=qParams?.business;
              // sessionStorage.setItem('contractor_id',qParams?.contractor_id)
              // sessionStorage.setItem('role','franchisee')
              this.router.navigate(['/dashboard'])
            }
          })
        });
      }
    })
    console.log('from contractor')
  }

  async requestTest() {
    // const localhost = 'http://localhost:3200'
    // const origin = this.document.location.origin
    // const baseUrl = isDevMode() ? localhost : origin

    // const data = await firstValueFrom(this.httpClient.get(`${baseUrl}/api/test`))
    // this.testData = data;
  }
}
