import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as bcrypt from 'bcryptjs';
import { environment } from 'src/environments/environment';
import { API_PATH } from '../utils/constants/api.constants';
@Injectable({
  providedIn:'root'
})
export class TokenService {
  private saltRounds = 10; // Adjust the number of rounds based on your security needs
  constructor(private http:HttpClient) {
  }

  generateToken(contractorId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      bcrypt.hash(contractorId, this.saltRounds, (err, hash) => {
        if (err) {
          reject(err);
        } else {
          resolve(hash);
        }
      });
    });
  }
  verifyToken(req){
    const url = environment.BASE_URL + API_PATH.API_BASE_PATH  + API_PATH.CHECK_CONTRACTOR;
    return this.http.post(url,{
      hased_secret_key: req?.token,
      contractor_id: req?.contractor_id,
      project_id: req?.project_id
  })
  }
}
