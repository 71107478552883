import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { API_PATH } from '../utils/constants/api.constants';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public isFranchisee:boolean;
  public franchiseeContractor_id:string;
  public franchiseeProjectid:string;
  public business:string;
  constructor(private http: HttpClient) { }

  getProfileData() {
    const url = environment.BASE_URL + API_PATH.API_BASE_PATH  + API_PATH.GET_PROFILE;
    return this.http.get<any>(url, {}); 
  }

  updateProfile(data) {
    const url = environment.BASE_URL + API_PATH.API_BASE_PATH + API_PATH.UPDATE_PROFILE ;
    return this.http.post<any>(url, data); 
  }

  getCountries() {
    const url = environment.BASE_URL + API_PATH.API_BASE_PATH + API_PATH.GET_COUNTRY_LIST ;
    return this.http.get<any>(url, {}); 
  }

  getSubCategory(params) {
    const url = environment.BASE_URL + API_PATH.API_BASE_PATH + API_PATH.GET_SUB_CATEGORY ;
    return this.http.get<any>(url, {params}); 
  }

  getCounties(params) {
    const url = environment.BASE_URL + API_PATH.API_BASE_PATH + API_PATH.GET_SUB_CATEGORY ;
    return this.http.get<any>(url, {params}); 
  }
}
